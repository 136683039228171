import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useMany,
  useList,
  useOne,
  useCreate,
  HttpError,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  DateField,
  DeleteButton,
  useSelect,
  useForm,
  CreateButton,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Modal,
} from "antd";
import { BASE_URL } from "authProvider";
import { FormInstance } from "antd/lib/form/Form";

const { RangePicker } = DatePicker;

interface IDashBoard {
  readonly totalExperienceCustomerCount: number;
  readonly totalExchangeGiftCustomerCount: number;
  readonly totalRegisterCustomerCount: number;
  readonly gifts: {
    readonly id: number;
    readonly title: string;
    readonly image: string;
    readonly customerGifts: any[];
  }[];
  readonly checkpoints: {
    readonly id: number;
    readonly title: string;
    readonly customerCheckpoints: any[];
  }[];
}

interface ICustomer {
  readonly quantity: number;
}

const Filter: React.FC<{
  searchForm: FormInstance;
  handleSubmit: (values: any) => void;
}> = ({ searchForm, handleSubmit }) => {
  const { selectProps: locationSelectProps } = useSelect({
    resource: "locations",
  });

  return (
    <Form layout="vertical" form={searchForm} onFinish={handleSubmit}>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Form.Item label="Địa điểm" name="locations">
            <Select {...locationSelectProps} allowClear mode="multiple" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Thời gian" name="date">
            <RangePicker allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Áp dụng
        </Button>
        <Button
          onClick={() => {
            searchForm.setFieldsValue({
              date: [],
              locations: [],
            });
            searchForm.submit();
          }}
          style={{ marginLeft: "10px" }}
          type="primary"
          danger
        >
          Đặt lại
        </Button>
      </Form.Item>
    </Form>
  );
};

export const Dashboard: React.FC<IResourceComponentsProps> = () => {
  const [searchFilter, setSearchFilter] = useState({
    date: [],
    locations: [],
  });
  const { data, refetch } = useOne<IDashBoard>({
    resource: "dashboard",
    id: `statistics?${searchFilter.date.length == 2
      ? `startDate=${searchFilter.date[0]}&endDate=${searchFilter.date[1]}`
      : ""
      }${searchFilter.locations.length > 0
        ? `${searchFilter.date.length == 2 ? "&" : ""}locations=${searchFilter.locations
        }`
        : ""
      }`,
  });

  const { form: searchForm } = useForm({});
  const { mutate, isLoading } = useCreate();

  const downloadFile = (fileUrl: string) => {
    const element = document.createElement("a");
    element.href = fileUrl;
    element.download = fileUrl.split('/')[fileUrl.split('/').length - 1];
    element.click();
  };

  // const generate = async () => {
  //   const res = await mutate({
  //     resource: "customers/generate",
  //     values: {
  //       amount: 100,
  //     },
  //     successNotification(data, values, resource) {

  //       downloadFile(`${BASE_URL}/${data?.data.data.uri}`)
  //       return {
  //         message: `Generate thành công`,
  //         description: "Success with no errors",
  //         type: "success",
  //       };
  //     },
  //   });
  // }
  const exportExcel = async () => {
    const res = await mutate({
      resource: "dashboard/export-admin",
      values: {
        locations: searchFilter.locations,
        startDate: searchFilter.date[0],
        endDate: searchFilter.date[1],
      },
      successNotification(data, values, resource) {

        downloadFile(`${BASE_URL}/${data?.data.data.uri}`)
        return {
          message: `Xuất file Excel thành công`,
          description: "Success with no errors",
          type: "success",
        };
      },
    });
  }

  useEffect(() => {
    refetch({});
  }, [searchFilter.date, searchFilter.locations]);

  return (
    <List>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {/* <Button loading={isLoading} onClick={generate}>Generate Mã QR</Button> */}
          <Button style={{ marginLeft: '10px' }} loading={isLoading} onClick={exportExcel}>Xuất Excel</Button>
        </Col>
        <Col span={24}>
          <Card title="Lọc">
            <Filter
              searchForm={searchForm}
              handleSubmit={(values: any) => {
                setSearchFilter({
                  date: (values.date || []).map((date: any) =>
                    date.toISOString()
                  ),
                  locations: values.locations || [],
                });
              }}
            />
          </Card>
        </Col>
        {/* <Col span={8}>
          <Card title="Số lượng khách tham gia trải nghiệm" bordered={false}>
            {data?.data?.totalExperienceCustomerCount || 0}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Số lượng khách đã đổi quà" bordered={false}>
            {data?.data?.totalExchangeGiftCustomerCount || 0}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Số lượng khách đã đăng ký gói cước" bordered={false}>
            {data?.data?.totalRegisterCustomerCount || 0}
          </Card>
        </Col>

        {(data?.data?.gifts || []).map((gift) => {
          return (
            <Col key={gift.id} span={6}>
              <Card
                title={gift.title}
                bordered={false}
                cover={
                  gift.image && (
                    <img
                      alt={gift.title}
                      src={`${BASE_URL}/${gift.image}`}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                  )
                }
              >
                {gift.customerGifts?.length || 0}
              </Card>
            </Col>
          );
        })} */}
      </Row>
      <Table
        style={{
          marginTop: '25px',
        }}
        dataSource={[
          {
            title: 'Số lượng khách tham gia trải nghiệm',
            total: data?.data?.totalExperienceCustomerCount || 0,
          },
          {
            title: 'Số lượng khách đã đổi quà',
            total: data?.data?.totalExchangeGiftCustomerCount || 0,
          },
          {
            title: 'Số lượng khách đã đăng ký gói cước',
            total: data?.data?.totalRegisterCustomerCount || 0,
          },
          ...(data?.data?.gifts || []).map((gift) => ({
            title: gift.title,
            total: gift.customerGifts?.length || 0,
          })),
          ...(data?.data?.checkpoints || []).map((checkpoint) => ({
            title: checkpoint.title,
            total: checkpoint.customerCheckpoints?.length || 0,
          })),
        ]}
        columns={[
          {
            title: 'Nội dung',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'Số lượng',
            dataIndex: 'total',
            key: 'total',
          },
        ]}
        pagination={false}
      />
    </List>
  );
};
